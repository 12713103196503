import { useEffect, useState } from "react";
import Head from "next/head";
import Moment from "react-moment";
import { t, Trans } from "@lingui/macro";
import Link from "next/link";
import Layout from "@/components/layout";
import Select from "react-select";
import {
  useGetMonthComparisonQuery,
  useGetOccupancyRateQuery,
  useGetTopProductsQuery,
  Attribute,
  DateRange,
  useGetSupplierDashboardPeriodsQuery,
} from "@/lib/services/orders";

import {
  useGetCategoryFiltersQuery,
  useGetCurrentCategoriesQuery,
} from "@/lib/services/inventory";

import CardTopMachine from "@/components/Dashboard/CardTopMachine";
import CardTotalRents from "@/components/Dashboard/CardTotalRents";
import CurrentOccupancy from "@/lib/features/dashboard/CurrentOccupancy";
import Collapse from "@/components/Collapse";
import {
  FilterIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { useGetGeneralStatisticsQuery } from "@/lib/services/orders";
import HistoricalOccupancy from "@/lib/features/dashboard/HistoricalOccupancy";
import MachineStatus from "@/lib/features/dashboard/MachineStatus";
import { useGetProfileQuery } from "@/lib/services/user";
import moment from "moment";
import { monthsBetweenDates, OptionsForSelects } from "@/lib/utils";

const SupplierDashboard = () => {
  const profileResult = useGetProfileQuery();
  const user = profileResult?.data?.user;

  const [months, setMonths] = useState<OptionsForSelects[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedAttrs, setSelectedAttrs] = useState<Attribute[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>({
    start: null,
    end: null,
  });

  const { data: periodsData } = useGetSupplierDashboardPeriodsQuery();

  useEffect(() => {
    if (!periodsData) return;

    let datestart = moment(periodsData?.start_date);
    let dateend = moment();

    if (!datestart.isValid() || !dateend.isValid()) return;

    setMonths(monthsBetweenDates(datestart, dateend).reverse());
  }, [periodsData]);

  const [currentMonth, setCurrentMonth] = useState<OptionsForSelects | null>(
    null
  );

  const handleCurrentMonth = (month: OptionsForSelects) => {
    const date = moment(month.value + "-01").startOf("month");
    setDateRange({
      start: date.format("YYYY-MM-DD"),
      end: date.endOf("month").format("YYYY-MM-DD"),
    });
    setCurrentMonth(month);
  };

  const { data: topMachines } = useGetTopProductsQuery({
    selectedAttrs,
    selectedCategories,
    dateRange,
  });

  const { data: comparisonData } = useGetMonthComparisonQuery({
    selectedAttrs,
    selectedCategories,
    dateRange,
  });

  const { data: categories } = useGetCurrentCategoriesQuery();
  const { data: attrs } = useGetCategoryFiltersQuery();
  const { data: occupancyRate } = useGetOccupancyRateQuery({
    selectedAttrs,
    selectedCategories,
    dateRange,
  });
  const { data: statisticsData } = useGetGeneralStatisticsQuery({});

  const handleOnChangeCategories = async (id: number) => {
    const newCategories = [...selectedCategories];
    const index = selectedCategories.findIndex((x) => x === id);

    if (index === -1) {
      newCategories.push(id);
      setSelectedCategories(newCategories);
    } else {
      newCategories.splice(index, 1);
      setSelectedCategories(newCategories);
    }
  };

  const handleOnChangeAttrs = async (name: string, id: string) => {
    const newAttrs = [...selectedAttrs];
    const index = selectedAttrs.findIndex((x) => x.id === id);

    if (index === -1) {
      newAttrs.push({
        name,
        id,
      });
      setSelectedAttrs(newAttrs);
    } else {
      newAttrs.splice(index, 1);
      setSelectedAttrs(newAttrs);
    }
  };

  const [IsCategoryListExpanded, setIsCategoryListExpanded] = useState(false);
  const categoriesForDisplay = IsCategoryListExpanded
    ? categories
    : categories?.slice(0, 3);
  return (
    <Layout title="Dashboard" hideTitle={true} current="Dashboard">
      <Head>
        <title>Dashboard | {t`rendalo-title`}</title>
      </Head>
      <div className="p-4">
        <div>
          <h1 className="mb-2 text-3xl">
            <b>
              <Trans>Welcome</Trans> {user?.first_name} {user?.last_name}
            </b>
          </h1>
          <h2>
            Hoy, <Moment format="DD [de] MMMM [de] YYYY">{new Date()}</Moment>
          </h2>
        </div>
        <div className="mt-4 grid grid-cols-7 2xl:mx-auto gap-4">
          <div className="p-4 bg-white lg:col-span-2 2xl:col-span-1 rounded-md">
            <div className="flex items-center justify-between">
              <h2 className="flex font-semibold">
                <FilterIcon className="h-[24px] text-primary mr-2" />{" "}
                <Trans>Filter</Trans>
              </h2>
              {/*<a href="#" className='text-primary'><Trans>Clear filters</Trans></a>*/}
            </div>
            <div className="">
              <div>
                <div className="mt-4">
                  <Select
                    placeholder={t`Select`}
                    options={months}
                    className="bg-light-blue-crayola"
                    value={currentMonth}
                    onChange={(item) =>
                      handleCurrentMonth(item as OptionsForSelects)
                    }
                  />
                  <span className="material-icons-outlined text-dark-jungle absolute top-2 left-2 z-[999]">
                    date_range
                  </span>
                </div>
              </div>
              <div className="pt-4">
                <h3 className="font-semibold">
                  <Trans>Categories</Trans>
                </h3>
                <ul className="mt-4">
                  {categoriesForDisplay?.map((cat, index) => (
                    <li className="mb-2" key={`${cat.slug}-${index}`}>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id={cat.slug}
                            aria-describedby="offers-description"
                            name={cat.slug}
                            type="checkbox"
                            onChange={() => handleOnChangeCategories(cat.id)}
                            className="w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary"
                          />
                        </div>
                        <div className="flex justify-between w-full ml-3 text-sm">
                          <label
                            htmlFor={cat.slug}
                            className="font-medium text-gray-700"
                          >
                            {cat.name}
                          </label>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  className="inline-flex items-center justify-start w-full text-xs font-semibold hover:text-radical-red hover:underline underline-offset-2"
                  onClick={() =>
                    setIsCategoryListExpanded(!IsCategoryListExpanded)
                  }
                >
                  {IsCategoryListExpanded ? (
                    <div className="flex gap-1">
                      <p className="text-safety-orange">{t`Show Less`}</p>
                      <span className="w-4 h-4 text-safety-orange">
                        <ChevronUpIcon />
                      </span>
                    </div>
                  ) : (
                    <div className="flex gap-1">
                      <p className="text-safety-orange">{t`Show more`}</p>
                      <span className="w-4 h-4 text-safety-orange">
                        <ChevronDownIcon />
                      </span>
                    </div>
                  )}
                </button>
              </div>
              <div className="mt-4">
                {attrs?.map((attr, index) => (
                  <Collapse
                    key={`attr-${index}`}
                    title={attr.name}
                    maxLength={0}
                  >
                    {attr.values.map((child, childIndex) => (
                      <li
                        className="my-2 ml-2 list-none"
                        key={`child-${childIndex}`}
                      >
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={`${child}-${childIndex}`}
                              aria-describedby="offers-description"
                              name={`${child}-${childIndex}`}
                              type="checkbox"
                              onChange={() =>
                                handleOnChangeAttrs(attr.name, child)
                              }
                              className="w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor={`${child}-${childIndex}`}
                              className="font-medium text-gray-700"
                            >
                              {child}
                            </label>
                          </div>
                        </div>
                      </li>
                    ))}
                  </Collapse>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className="grid grid-cols-6 grid-rows-4 gap-4">
              <div className="bg-white col-span-4 row-span-2 rounded-md">
                <MachineStatus data={statisticsData} />
              </div>
              {comparisonData && (
                <div className="bg-white col-span-2 row-span-1 rounded-md">
                  <CardTotalRents data={comparisonData} />
                </div>
              )}
              <div className="bg-white col-span-2 row-span-1 rounded-md">
                <CardTopMachine data={topMachines} />
              </div>
              <div className="bg-white col-span-4 row-span-2 rounded-md">
                <HistoricalOccupancy data={occupancyRate} />
              </div>
              <div className="bg-white col-span-2 row-span-2 rounded-md">
                <CurrentOccupancy data={statisticsData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupplierDashboard;
