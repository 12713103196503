import React from "react";
import dynamic from "next/dynamic";

import { Tooltip as FlowTooltip } from "flowbite-react";
import { t, Trans } from "@lingui/macro";
import {
  InformationCircleIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import { OccupancyPeriod } from "@/lib/dto/dashboard";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

interface Props {
  data: OccupancyPeriod[] | undefined;
}

const HistoricalOccupancy = ({ data }: Props) => {
  const options: any = {
    chart: {
      height: 250,
      type: "bar",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#256EFF"],
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: (value: number) => {
          return value.toFixed(0);
        },
      },
    },
    xaxis: {
      categories: data ? data.map((x) => x.label) : [],
      labels: {
        show: true,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  let serie: any[] = [];

  if (data)
    data.forEach((period) => {
      let { occupated, total } = period;
      if (occupated === 0 || total === 0) {
        serie.push(0);
      } else {
        serie.push(((occupated * 100) / total).toFixed(2));
      }
    });

  const series = [
    {
      name: "",
      data: serie,
    },
  ];

  return (
    <div className="px-4 pb-4 pt-4">
      <div className="flex items-center mb-4 justify-between">
        <h1 className="text-lg flex items-center font-semibold">
          <TrendingUpIcon className="h-[24px] text-primary inline-block mr-2" />
          <Trans>Historical occupancy rate</Trans>
        </h1>
        <FlowTooltip
          content={t`Tasa de ocupación promedio de tu flota en meses anteriores`}
          trigger="hover"
        >
          <InformationCircleIcon className="h-[24px] text-gray-400" />
        </FlowTooltip>
      </div>
      {data && (
        <div style={{ height: 250 }}>
          <Chart options={options} series={series} type="bar" height={250} />
        </div>
      )}
    </div>
  );
};

export default HistoricalOccupancy;
