import {
  InformationCircleIcon,
  CurrencyDollarIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  MenuAlt4Icon,
} from "@heroicons/react/outline";
import { MonthComparison } from "../../lib/dto/dashboard";
import { Trans, t } from "@lingui/macro";
import { Tooltip as FlowTooltip } from "flowbite-react";

interface Props {
  data: MonthComparison;
}

const CardTotalRents = ({ data }: Props) => {
  return (
    <div className="px-4 pb-4 pt-4 ">
      <div className="flex items-center mb-2 justify-between">
        <h1 className="text-lg flex items-center font-semibold ">
          <CurrencyDollarIcon className="h-[24px] text-primary inline-block mr-2" />
          <Trans>Total leases</Trans>
        </h1>
        <FlowTooltip
          content={t`Número de arriendos del mes y la comparación con el mes anterior`}
          animation={false}
          trigger="hover"
        >
          <InformationCircleIcon className="h-[24px] text-gray-400" />
        </FlowTooltip>
      </div>
      <div className="flex items-baseline mb-4">
        <h1 className="text-5xl mr-2 font-semibold ">{data.leases}</h1>
        <p className="text-xl text-gray-400">
          <Trans>leases</Trans>
        </p>
      </div>
      <div className="flex items-center">
        {data.growth > 0 && (
          <ArrowUpIcon className="h-5 w-5 text-tiffany-blue" />
        )}
        {data.growth < 0 && (
          <ArrowDownIcon className="h-5 w-5 text-radical-red" />
        )}
        {data.growth === 0 && (
          <MenuAlt4Icon className="h-5 w-5 text-blue-crayola" />
        )}
        <p className="ml-2 font-semibold">
          {data.growth ?? 0}% <Trans>to the previous month</Trans>
        </p>
      </div>
    </div>
  );
};

export default CardTotalRents;
