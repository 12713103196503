import React, { useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";

interface Props {
  children: any[];
  maxLength: number;
  title: string;
}

export default function Collapse({ maxLength, title, children }: Props) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <a className="link cursor-pointer" onClick={() => setShowMore(!showMore)}>
        <div className="flex py-2 border border-x-0 border-b-0 items-center justify-between">
          <h3 className="capitalize text-dark-jungle font-semibold">{title}</h3>
          {showMore ? (
            <MinusIcon className="h-[16px] text-dark-jungle" />
          ) : (
            <PlusIcon className="h-[16px] text-dark-jungle" />
          )}
        </div>
      </a>
      {showMore ? children.map((child) => child) : <></>}
    </>
  );
}
