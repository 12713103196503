import { DashboardPage } from "../lib/features/auth/DashboardPage";
import { useGetProfileQuery } from "@/lib/services/user";
import MySpace from "./my-space";
import ManagementDashboard from "./management";
import SupplierDashboard from "@/components/Dashboard/SupplierDashboard";

const Home: DashboardPage = () => {
  const profileResult = useGetProfileQuery();
  const isSuperUser = profileResult.data?.user.is_superuser;
  const isRendalo = profileResult.data?.supplier.is_rendalomaq;

  if (!isRendalo) {
    return <SupplierDashboard />;
  } else if (isSuperUser) {
    return <ManagementDashboard />;
  }

  return <MySpace />;
};

Home.auth = true;
export default Home;
