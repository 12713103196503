import { StarIcon } from "@heroicons/react/outline";
import { Trans } from "@lingui/macro";
import Image from "next/image";

interface Props {
  data: any;
}

const CardTopMachine = ({ data }: Props) => {
  if (!data) return <></>;

  const product = data[0]?.supplier_product;

  return (
    <div className="px-4 pb-4 pt-4">
      <div className="flex items-center mb-2 justify-between">
        <div className="text-lg flex items-center font-semibold">
          <StarIcon className="h-[24px] text-primary inline-block mr-2" />
          <h2 className="font-semibold">
            <Trans>Most leased machine</Trans>
          </h2>
        </div>
      </div>
      <div className="flex items-center">
        {product && product.image ? (
          <>
            <div className="h-20 w-20 relative rounded-full overflow-hidden mr-4">
              <Image
                layout="fill"
                className="object-contain"
                src={product.image}
                alt="image product"
              />
            </div>
            <div>
              <h3 className="text-md font-semibold">{product.name}</h3>
              <p className="text-md text-gray-500">{product.category.name}</p>
            </div>
          </>
        ) : (
          <>
            <p className="text-md text-gray-500">
              <Trans>No records found</Trans>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CardTopMachine;
