// Graphs
import React from "react";
import Image from "next/image";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { t, Trans } from "@lingui/macro";
import { ChartPieIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { Tooltip as FlowTooltip } from "flowbite-react";
import { GeneralStatistics as GeneralStatisticsData } from "@/lib/dto/dashboard";

let plugin = {
  id: "idOfPlugin",
  beforeDraw: function (chart: any) {
    if (chart?.config?.options?.elements?.center) {
      // Get ctx from string
      var ctx = chart?.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || "Arial";
      var txt = centerConfig.text;
      var sbttl = centerConfig.subtitle;
      var color = centerConfig.color || "#000";
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var sbttlWidth = ctx.measureText(sbttl).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth + sbttlWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = txt.split(" ");
      var line = "";
      var lines = [];

      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + " ";
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  },
};

ChartJS.register(ArcElement, Tooltip, Legend, plugin);

interface Props {
  data?: GeneralStatisticsData;
}

const GeneralStatistics = ({ data: statisticsData }: Props) => {
  const options: any = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      center: {
        text: `${statisticsData?.total}`,
        subtitle: "Total de máquinas",
        color: "#000", // Default is #000000
        fontStyle: "Nunito Sans", // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25, // Default is 25 (in px), used for when text wraps
      },
    },
  };

  const percentage = (num: number) => {
    const total = statisticsData?.total;

    if (!total || !num) return "0%";

    return `${((num * 100) / total).toFixed(0)} %`;
  };

  let data: number[] = [0, 0, 0, 0, 0];

  if (statisticsData) {
    data[0] = statisticsData.available;
    data[1] = statisticsData.leased;
    data[2] = statisticsData.leased_by_rendalomaq;
    data[3] = statisticsData.not_available;
    data[4] = statisticsData.out_of_range;
  }

  const generalData = {
    labels: [t`Available`, t`Leased`, t`Not available`],
    datasets: [
      {
        label: t`State of the machines today`,
        data: statisticsData ? data : [0, 0],
        backgroundColor: ["#17C3B2", "#ff7500", "#1F2937"],
        borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="flex flex-col px-4 pb-4 pt-4">
      <div className="">
        <div className="flex items-center justify-between">
          <h1 className="text-lg flex items-center font-semibold">
            <ChartPieIcon className="h-[24px] text-primary inline-block mr-2" />
            <Trans>State of the machines today</Trans>
          </h1>
          <FlowTooltip
            content={t`Número de máquinas de la flota y su estado según disponibilidad`}
            animation={false}
            trigger="hover"
          >
            <InformationCircleIcon className="h-[24px] text-gray-400" />
          </FlowTooltip>
        </div>
        <div className="flex justify-around items-center">
          <div className="h-full p-8 w-[288px]">
            <Doughnut
              options={options}
              data={generalData}
              style={{ height: 188, width: 188 }}
            />
          </div>
          {statisticsData && (
            <div className="w-full">
              <div className="flex justify-between items-center">
                <p className="text-lg ">
                  <span className="inline-block h-3 w-3 mr-4 rounded-full bg-safety-orange"></span>
                  <Trans>Leased</Trans>
                </p>
                <p className=" font-semibold">
                  ({statisticsData?.leased}){" "}
                  {percentage(statisticsData?.leased)}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-lg ">
                  <span className="inline-block h-3 w-3 mr-4 rounded-full bg-[#17C3B2]"></span>
                  <Trans>Available</Trans>
                </p>
                <p className=" font-semibold">
                  ({statisticsData?.available}){" "}
                  {percentage(statisticsData?.available)}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-lg ">
                  <span className="inline-block h-3 w-3 mr-4 rounded-full bg-gray-300"></span>
                  <Trans>Not available</Trans>
                </p>
                <p className=" font-semibold">
                  ({statisticsData.not_available}){" "}
                  {percentage(statisticsData.not_available)}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneralStatistics;
