import React, { Component } from "react";
import dynamic from "next/dynamic";
import { t, Trans } from "@lingui/macro";
import {
  CheckCircleIcon,
  XCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/solid";
import {
  InformationCircleIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";

import { GeneralStatistics } from "@/lib/dto/dashboard";
const ReactApexChart = dynamic(() => import("react-apexcharts"), {
  ssr: false,
});

import { Tooltip as FlowTooltip } from "flowbite-react";
import { ApexOptions } from "apexcharts";

interface Props {
  data?: GeneralStatistics;
}

export default function CurrentOccupancyRate({ data }: Props) {
  const series = data ? [(data.leased * 100) / data.total] : [0];

  const totalLeased = data ? data.leased : 0;

  const OccupancyRate =
    data && totalLeased > 0
      ? Number(((totalLeased / data.total) * 100).toFixed(0))
      : 0;

  const rate = (val: number) => {
    if (!val && val !== 0)
      return {
        name: t`undefined`,
        color: "#888",
      };

    if (val >= 70)
      return {
        name: t`optimum`,
        color: "#3AC3B6",
      };
    if (val >= 40)
      return {
        name: t`regular`,
        color: "#FAB900",
      };

    return {
      name: t`deficient`,
      color: "#FF4062",
    };
  };

  var options: ApexOptions = {
    series: [OccupancyRate],
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 2,
          size: "30%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "14px",
            offsetY: 8,
          },
        },
      },
    },
    colors: [rate(OccupancyRate).color],
    labels: [`${OccupancyRate}%`],
    legend: {
      show: false,
      floating: true,
      position: "left",
      fontSize: "10px",
      offsetY: 5,
      labels: {
        useSeriesColors: false,
      },
      formatter: function (
        seriesName: string,
        opts: {
          w: { globals: { series: { [x: string]: string } } };
          seriesIndex: string | number;
        }
      ) {
        return (
          seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
        );
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <div id="card" className="p-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="text-lg flex items-center font-semibold">
          <TrendingUpIcon className="h-[24px] text-primary inline-block mr-2" />
          <Trans>Current occupancy rate</Trans>
        </h1>
        <FlowTooltip
          content={t`Optimo > 70% | Regular > 40% | Deficiente < 40%`}
          animation={false}
          trigger="hover"
        >
          <InformationCircleIcon className="h-[24px] text-gray-400" />
        </FlowTooltip>
      </div>
      <div
        id="chart"
        className="-mt-8 flex w-full justify-between items-center"
      >
        <ReactApexChart
          options={options}
          series={options.series}
          type="radialBar"
          height={300}
        />
      </div>
      <div className="-mt-8 flex w-full justify-between items-center">
        <div>
          <span className="text-gray-400">
            <Trans>Current status</Trans>
          </span>
          <h2 className="text-xl capitalize">
            <strong>{rate(series[0]).name}</strong>
          </h2>
        </div>
        {series[0] >= 70 && <CheckCircleIcon className="h-16 text-[#3AC3B6]" />}
        {series[0] >= 40 && series[0] < 70 && (
          <MinusCircleIcon className="h-16 text-[#FAB900]" />
        )}
        {series[0] < 40 && <XCircleIcon className="h-16 text-[#FF4062]" />}
      </div>
    </div>
  );
}
